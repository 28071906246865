"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Bucket.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgBucket = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M8 28h12c11-1.5 0-13 0-13h-3L8 28Zm0 0H7M18.5 15a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "m17 13-6-7 2-2 6 8"
        }
      )
    ]
  }
));
var Bucket_default = SvgBucket;

export {
  Bucket_default
};
