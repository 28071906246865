"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Categories.tsx
import { jsx } from "react/jsx-runtime";
var SvgCategories = withPhosphorWrapper((props) => /* @__PURE__ */ jsx(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: /* @__PURE__ */ jsx(
      "path",
      {
        stroke: "currentColor",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 1.5,
        d: "M14 6H6v8h8V6ZM26 6h-8v8h8V6ZM14 18H6v8h8v-8ZM26 18h-8v8h8v-8Z"
      }
    )
  }
));
var Categories_default = SvgCategories;

export {
  Categories_default
};
