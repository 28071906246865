import { forwardRef, useState, type ChangeEventHandler, type MouseEventHandler } from 'react';
import { VisuallyHidden, SearchInput, SearchInputProps } from '@mezzoforte/forge';
import { gtmService } from 'util/gtm-service';

export interface SearchFormProps {
  placeholder?: string;
  width?: SearchInputProps['width'];
}

const Component = forwardRef<HTMLInputElement, SearchFormProps>((props, ref) => {
  const [searchTerm, setSearchTerm] = useState('');
  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => setSearchTerm(event.currentTarget.value);
  const clearSearch: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setSearchTerm('');
  };

  return (
    <form action="/haku" onSubmit={() => gtmService.recommended.search(searchTerm)}>
      <label>
        <VisuallyHidden>Hae sivustolta</VisuallyHidden>
        <SearchInput
          id="search-input"
          ref={ref}
          name="term"
          onChange={onChange}
          onClickReset={clearSearch}
          value={searchTerm}
          {...props}
        />
      </label>
    </form>
  );
});

Component.displayName = 'SearchForm';

export const SearchForm = Component;
