"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Electronics.tsx
import { jsx } from "react/jsx-runtime";
var SvgElectronics = withPhosphorWrapper((props) => /* @__PURE__ */ jsx(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: /* @__PURE__ */ jsx(
      "path",
      {
        stroke: "currentColor",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 1.5,
        d: "M9 21v-4M6 4v4M12 4v4M9 17a5 5 0 0 0 5-5V8H4v4a5 5 0 0 0 5 5ZM9 20v1a5 5 0 0 0 10 0v-1M19 20v-4a5 5 0 0 1 5-5h2"
      }
    )
  }
));
var Electronics_default = SvgElectronics;

export {
  Electronics_default
};
