import Cookies from 'js-cookie';
import axios from 'axios';
import { useSession } from './useSession';
import { useCookieConsent } from './useCookieConsent';
import { useEnv } from './useEnv';
import { useCallback, useMemo } from 'react';

const browserSize = (width: number, height: number): string => `${width}x${height}`;

const useSendAnalytics = (defaultType?: string, initialData?: Record<string, unknown>) => {
  const { achelousUrl } = useEnv();
  const { currentUser } = useSession();
  const { cookieConsent } = useCookieConsent();
  const user = currentUser.data?.user ?? null;

  const client = useMemo(() => axios.create({ baseURL: achelousUrl }), [achelousUrl]);

  const sendEvent = useCallback(
    (eventType: string, data: any) => {
      if (process.env.NODE_ENV === 'development') {
        console.dir({ eventType, ...data });
      }
      client.post('/api/events', { type: eventType, data });
    },
    [client]
  );

  const sendAnalytics = useCallback(
    (type?: string, data?: Record<string, unknown>) => {
      // Check if statistics consent was given
      if (!cookieConsent.statistics) {
        return;
      }

      const finalType = type ?? defaultType;
      if (!finalType) return console.error('No type given!');

      const generalData = {
        userId: user?.uuid ?? null,
        visitorId: Cookies.get('visitor-id'),
        userAgent: navigator.userAgent,
        language: navigator.language,
        screenSize: browserSize(window.screen.availWidth, window.screen.availHeight),
        href: window.location.href,
        referrer: document.referrer,
      };

      if (!user || (user && !user.isAdmin && !user.preventDataCollection)) {
        console.log('Send data', type, { ...data, generalData });
        sendEvent(finalType, { ...initialData, ...data, ...generalData });
      }
    },
    [cookieConsent, user, sendEvent, defaultType, initialData]
  );

  return sendAnalytics;
};

export default useSendAnalytics;
