"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Moped.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgMoped = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsxs("g", { clipPath: "url(#moped_svg__a)", children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 1.5,
            d: "M24 26a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM12 22a4 4 0 0 1-8 0"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 1.5,
            d: "M17.5 22c0-3 2.282-6.5 6.5-6.5 2.5 0 4 1.5 4 1.5"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeWidth: 1.5,
            d: "M2 18a4 4 0 0 1 4-4h7a7.547 7.547 0 0 0 0 8H2.5a.5.5 0 0 1-.5-.5V18ZM5 13a2 2 0 0 1 2-2h4.6a2 2 0 0 1 2 2 1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeWidth: 1.5,
            d: "M13 22h4.5"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            fill: "currentColor",
            d: "M17.194 6.25a.75.75 0 0 0-.03 1.5l.03-1.5Zm6.021 9.635L20.502 7.17l-1.432.446 2.713 8.714 1.432-.446Zm-3.881-9.592-2.14-.043-.03 1.5 2.14.042.03-1.5Zm1.168.878a1.25 1.25 0 0 0-1.168-.878l-.03 1.5a.25.25 0 0 1-.234-.176l1.432-.446Z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeWidth: 1.5,
            d: "m20.516 10.384 2.042-1.386a.8.8 0 0 1 1.183.343l.482 1.108a.8.8 0 0 1-.575 1.104l-1.988.402"
          }
        )
      ] }),
      /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "moped_svg__a", children: /* @__PURE__ */ jsx("path", { fill: "#fff", d: "M0 0h32v32H0z" }) }) })
    ]
  }
));
var Moped_default = SvgMoped;

export {
  Moped_default
};
